<template>
  <div id="order-list">
    <Tabs @click="tabChange" v-model="orderType">
      <Tab title="全部" name=""></Tab>
      <Tab title="待支付" name="0"></Tab>
      <Tab title="已完成" name="1"></Tab>
      <Tab title="已失效" name="2"></Tab>
    </Tabs>
    <div class="orders">
      <div class="order" v-for="(order, index) in orderList" :key="index">
        <div class="orderNum">
          订单编号:{{ order.orderNumber }}
          <span
            v-if="order.status"
            :class="['orderStatus', `status${order.status.value}`]"
            >{{ order.status.name }}</span
          >
        </div>
        <div class="combo">
          <div
            class="course"
            v-for="(course, cIndex) in order.courses"
            :key="cIndex"
          >
            <img :src="course.coverUrl" alt="" />
            <div class="courseName">{{ course.name }}</div>
          </div>
        </div>
        <div class="total">
          总价:<span class="price"
            >￥{{ order.rmbPayAmount }}/${{ order.payPalPrice }}</span
          >
          <div v-if="order.status.value == 0" class="operatebutton">
            <el-button size="mini" round class="pay" @click="pay(order.orderId)"
              >立即支付</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <Pagination
      v-model="currentPage"
      :total-items="total"
      :items-per-page="pageSize"
      @change="getOrderList(currentPage)"
    ></Pagination>
  </div>
</template>
<script>
import { getOrderList } from "@/service/userCenter";
import { Tab, Tabs, Pagination, Dialog } from "vant";
export default {
  name: "OrderList",
  components: {
    Tab,
    Tabs,
    Pagination,
  },
  data() {
    return {
      orderList: [],
      currentPage: 1,
      total: 0,
      orderType: "",
      pageSize: 10,
    };
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    getOrderList(page) {
      this.currentPage = page ? page : 1;
      getOrderList(this.orderType, this.currentPage, this.pageSize)
        .then((res) => {
          if (!res.result.code) {
            this.orderList = res.result.records;
            this.total = res.result.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tabChange(name, title) {
      this.getOrderList(1);
    },
    pay(orderId) {
      this.$router.push(`/pay/order?orderId=${orderId}`);
    },
    // cancelOrder(orderId) {
    //   Dialog.confirm({
    //     title: "提示",
    //     message: "确定取消该订单？",
    //   })
    //     .then(() => {
    //       // on confirm
    //     })
    //     .catch(() => {
    //       // on cancel
    //     });
    // },
  },
};
</script>
<style lang="scss" scoped>
#order-list {
  margin-top: 54px;
}
.orders {
  padding: 10px 0;
  background: #f8f8f8;
  .order {
    background: #fff;
    margin-bottom: 10px;
    padding: 0 12px;
    &:last-child {
      margin-bottom: 0;
    }
    .orderNum {
      font-size: 12px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      line-height: 30px;
      color: #979797;
      border-bottom: 1px solid #e7e7e7;
    }
    .orderStatus {
      float: right;
      font-size: 12px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      line-height: 30px;
      &.status0 {
        color: #fd1d1d;
      }
      &.status1 {
        color: #7eca33;
      }
      &.status2 {
        color: #979797;
      }
    }
  }
}
.combo {
  border-bottom: 1px solid #e7e7e7;
  .course {
    padding: 12px 8px;
    display: flex;
    flex-wrap: nowrap;
    img {
      width: 110px;
      height: 62px;
      border-radius: 2px;
      margin-right: 10px;
      vertical-align: top;
    }
    .courseName {
      width: calc(100% - 120px);
      font-size: 12px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}
.total {
  font-size: 10px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #979797;
  line-height: 42px;
  .price {
    font-size: 12px;
    color: #fd1d1d;
  }
  .operatebutton {
    float: right;
    display: flex;
    transform: translateY(6px);
    .cancel {
      font-size: 12px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #979797;
      background: #fff;
    }
    .pay {
      background: linear-gradient(
        135deg,
        #ff7809 0%,
        #ffcf00 100%,
        #ff9568 100%
      );
      color: #fff;
    }
  }
}
</style>